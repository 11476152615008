import React, { useContext, useEffect, useReducer, createContext } from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import LoginPage from "./components/Products/LoginPage";
import Products from "./components/Products/Products";
import ProductDashboard from "./components/Products/ProductDashboard";
import ProductOrders from "./components/Products/ProductOrders";
import MMProductOrders from "./components/Products/MMProductOrders";
import LJProductOrders from "./components/Products/LJProductOrders";
import ProductForms from "./components/Products/ProductForms";
import SubscriptionOrders from "./components/Products/SubscriptionOrders";
import "./index.css";
import { reducer, initialState } from "./reducers/authreducer";
import { Provider } from "react-redux";
import { createStore } from "redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import WarehouseDashboard from './components/Warehouse/WarehouseDashboard';
import UnshippedOrders from "./components/Warehouse/UnshippedOrders";
import FreeGifts from "./components/Products/FreeGifts";
import SubscriptionDetails from "./components/Products/SubscriptionDetails";
import Subscriptions from "./components/Products/Subscriptions";
export const UserContext = createContext();
const Routing = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(UserContext);
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            dispatch({ type: "USER", payload: user })
            if(history.location.pathname.includes("login"))
                history.push('/product')
        }
        else {
            history.push('/product/login');
        }
    }, [])
  return (
    // <Provider store={store}>

    <Switch>
      <Route path="/product/login" component={LoginPage} />
      <Route exact path="/product" component={ProductDashboard} />
      {/* <Route path="/product/products" component={Products} />
      <Route path="/product/categories" component={Products} />
      <Route path="/product/regimes" component={Products} />
      <Route path="/product/forms" component={Products} />
      <Route path="/product/mm-forms" component={Products} />
      <Route path='/product/faq' component={Products} />
      <Route path='/product/home' component={Products} />
      <Route path="/product/pages" component={Products} />
      <Route path='/product/allproducts' component={Products} />
      <Route path="/product/orders" component={ProductOrders} />
      <Route path="/product/mm-orders" component={MMProductOrders} />
      <Route path="/product/lj-orders" component={LJProductOrders} />
      <Route path="/product/subscription-orders" component={SubscriptionOrders} />
      <Route path="/product/subscriptions" component={Subscriptions} />
      <Route path="/product/subscription/:subscriptionId" component={SubscriptionDetails} />
      <Route path="/product/free-gifts" component={FreeGifts} />
      <Route path="/product/bwforms" component={ProductForms} />
      <Route path="/product/notifications" component={Products} />
      <Route path="/product/newhome" component={Products} />
      <Route path="/product/products-raw" component={Products} />
      <Route path="/product/custom-page" component={Products} />
      <Route path="/product/mm-products-raw" component={Products} />
      <Route path="/product/mm-android-products-raw" component={Products} />
      <Route path="/product/lj-products-raw" component={Products} />
      <Route path="/product/lj-pages" component={Products} />
      <Route path="/product/mm-categories" component={Products} />
      <Route path="/product/mm-app-categories" component={Products} />
      <Route path="/product/mm-android-jsons" component={Products} />
      <Route path="/product/notification-bars" component={Products} />
      <Route path="/product/search" component={Products} />
      <Route path="/product/subscription" component={Products} />
      <Route path="/product/refer-and-earn" component={Products} />
      <Route path="/product/direct-assesment" component={Products} />
      <Route path="/product/mm-direct-assesment" component={Products} />
      <Route path="/product/consult" component={Products} />
      <Route path="/product/reviews-and-questions" component={Products} />
      <Route path="/product/ingredients" component={Products} />
      <Route path="/product/pincode" component={Products} />
      <Route path="/product/community" component={Products} />
      <Route path="/product/direct-assesment-flow" component={Products} />
      <Route path="/product/sitemap" component={Products} />
      <Route path="/warehouse/unshippedOrders" component={UnshippedOrders} />
      <Route exact path="/warehouse" component={WarehouseDashboard} />
      <Route path="/product/wellness-manual" component={Products} />
      <Route path="/product/free-gift-mm" component={Products} />
      <Route path="/product/free-gift-bw" component={Products} />
      <Route path="/product/free-gift-lj" component={Products} />
      <Route path="/product/mm-concerns" component={Products} />
      <Route path="/product/mw-configurations-MM" component={Products} />
      <Route path="/product/mw-configurations-BW" component={Products} />
      <Route path="/product/mw-configurations-LJ" component={Products} />
      <Route path="/product/mm-homepage" component={Products} />
      <Route path="/product/mm-all-products" component={Products} />
      <Route path="/product/mm-pdp-url-map" component={Products} />
      <Route path="/product/slot-capacity" component={Products} /> */}
      <Route path="/product/rules-json" component={Products} />
      <Route path="/" component={LoginPage} />
    </Switch>
    // </Provider>
  );
};
const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <UserContext.Provider value={{ state, dispatch }}>
      <BrowserRouter>
        <Routing></Routing>
      </BrowserRouter>
    </UserContext.Provider>
  );
};
export default App;

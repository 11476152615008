const baseUrl =
  "https://stg.api.bebodywise.com/mosaic-dashboard";
const ljBaseUrl =
  "https://stg.api.ourlittlejoys.com/mosaic-dashboard";
const mmBaseUrl =
  "https://stg.api.manmatters.com/mosaic-dashboard";
const formServiceUrl = "https://stg.api.mosaicwellness.in/form-service"

export const staging = {
  dashboard_api: baseUrl,
  subscription_product_order:baseUrl + "/subscription-orders",
  product_order: baseUrl + "/magento",
  mm_product_order: mmBaseUrl + "/magento",
  lj_product_order: ljBaseUrl + "/magento",
  product_forms: formServiceUrl + "/adminBodywiseFormData",
  GET_CONFIRMED_ORDERS: baseUrl + "/getConfirmedOrders",
  GET_SUBSCRIPTIONS: baseUrl + "/subscriptions",
  UPDATE_SUBSCRIPTION: baseUrl + "/subscriptions/update",
  SEARCH_SUBSCRIPTIONS: baseUrl + "/subscriptions/search",
  GET_SUBSCRIPTION_DETAILS: baseUrl + "/subscriptions/details",
  CANCEL_SUBSCRIPTION: baseUrl + "/subscriptions/cancel",
  FREE_GIFTS: baseUrl + "/free-gifts",
};
